import { all } from "redux-saga/effects";
import {
  listenGetToken,
  listenSetSessionToken,
  listenSetToken,
} from "./auth.sagas";
import { listengetRoles, listenSetHG } from "./authr.sagas";
import { listengetBookingStatus } from "./booking.sagas";
import {
  listenGetConstants,
  listenGetCountries,
  listenGetCurrencies,
  listenGetHotelFloorAssign,
  listenGetHotelRoomCategory,
  listenGetLanguages,
  listenGetPms,
  listenGetPmsRoomsForParent,
  listenGetStates,
  listenGetPmsProducts,
} from "./constants.sagas";
import {
  fetchAddAssignRoom,
  fetchAddHotelFloor,
  fetchEditFloorAssignment,
  fetchEditHotelFloor,
  fetchGetHotelFloorDetails,
  fetchHotelFloorNameList,
  fetchHotelFloors,
  fetchSoftDeleteFloor,
} from "./floor.sagas";
import {
  fetchAddHotelTags,
  fetchHotelConfiguration,
  fetchHotelDetail,
  fetchHotelTags,
  fetchRemoveHotelTag,
  fetchSearchedHotelTags,
  fetchUpdateHotelDetails,
} from "./hotelDetail.sagas";
import {
  listenGetHotel,
  listenGetHotelByHotelGroups,
  listenGetHotelGroupList,
  listenHotelManagementResetState,
  listenAddTrackingData,
} from "./hotelmanagement.sagas";
import {
  createHotelRoom,
  fetchGetRoomDetails,
  listenfetchRoomList,
  listenupdateRoom,
  fetchSoftDeleteRoom,
} from "./hotelRoom.sagas";
import {
  fetchHotelLanguageSettings,
  fetchStoreHotelLanguageSettings,
  listenGetLanguage,
} from "./LanguageSettings.sagas";
import {
  listenActivateUser,
  listenChangePassword,
  listenconfirmPassword,
  listenDeactivateUser,
  listenforgotPassword,
  listenLogin,
  listenLogOut,
  listenResetPassword,
  listenSetPassword,
} from "./login.sagas";
import {
  listenaddOffer,
  listenEditOffer,
  listeneditSearchOffers,
  listenGetOffer,
  listenGetOfferById,
  fetchSoftDeleteOffer,
} from "./offers.sagas";
import {
  listenGetExportedFile,
  listenGetHotelFromHotelGroup,
  listenGetReports,
} from "./report.sagas";
import {
  addRoomCategories,
  editRoomCategory,
  fetchPMSRoomTypes,
  fetchRoomCategories,
  fetchRoomCategoryById,
  listensortCategories,
  fetchSoftDeleteRoomCategory,
} from "./roomCategories.sagas";
import {
  fetchCreateHotelSeasonPricing,
  fetchHotelSeasonPricing,
  fetchHotelSeasonPricingDetail,
  fetchUpdateHotelSeasonPricing,
  fetchSoftDeleteSeason,
} from "./seasonPricing.sagas";
import {
  listenaddService,
  listeneditSearchService,
  listeneditService,
  listenEmailService,
  listenGetDealType,
  listenGetEmailService,
  listengetServices,
  listenServiceById,
  fetchSoftDeleteDeal,
} from "./service.sagas";
import {
  fetchSaveTranslation,
  fetchTranslationTabs,
} from "./translation.sagas";
import {
  listenAddUser,
  listengetUserById,
  listengetUserProfile,
  listengetUsers,
  listenUpdateUser,
  listenupdateUserProfile,
} from "./userManagement.sagas";
import {
  fetchSoftDeleteCoupon,
  listenCouponById,
  listenGetCouponType,
  listenaddCoupon,
  listeneditCoupon,
  listeneditSearchCoupon,
  listengetCoupons,
} from "./coupon.sagas";

export default function* rootSaga() {
  yield all([
    listenSetHG(),
    listenGetLanguages(),
    listenGetPms(),
    listenGetPmsRoomsForParent(),
    listenGetHotelRoomCategory(),
    listenGetHotelByHotelGroups(),
    listenGetHotelFloorAssign(),
    listenGetConstants(),
    listenGetHotelGroupList(),
    listenLogin(),
    listenGetToken(),
    listenSetToken(),
    fetchHotelDetail(),
    fetchHotelTags(),
    fetchAddHotelTags(),
    fetchRemoveHotelTag(),
    fetchSearchedHotelTags(),
    fetchUpdateHotelDetails(),
    fetchAddHotelFloor(),
    fetchGetHotelFloorDetails(),
    fetchEditHotelFloor(),
    fetchRoomCategories(),
    fetchPMSRoomTypes(),
    addRoomCategories(),
    fetchRoomCategoryById(),
    editRoomCategory(),
    fetchHotelFloors(),
    fetchSoftDeleteFloor(),
    fetchSoftDeleteRoomCategory(),
    fetchSoftDeleteSeason(),
    fetchSoftDeleteRoom(),
    fetchSoftDeleteOffer(),
    fetchSoftDeleteDeal(),
    fetchHotelLanguageSettings(),
    fetchStoreHotelLanguageSettings(),
    createHotelRoom(),
    fetchGetRoomDetails(),
    listengetServices(),
    listeneditSearchService(),
    listenaddService(),
    listenGetOffer(),
    listenGetOfferById(),
    listenaddOffer(),
    listeneditSearchOffers(),
    listenServiceById(),
    listeneditService(),
    listenEditOffer(),
    listensortCategories(),
    listenfetchRoomList(),
    fetchHotelSeasonPricingDetail(),
    fetchCreateHotelSeasonPricing(),
    fetchUpdateHotelSeasonPricing(),
    fetchHotelSeasonPricing(),
    listenupdateRoom(),
    fetchAddAssignRoom(),
    fetchHotelFloorNameList(),
    fetchEditFloorAssignment(),
    fetchTranslationTabs(),
    fetchSaveTranslation(),
    listenGetCurrencies(),
    listenGetLanguage(),
    listenLogOut(),
    listenforgotPassword(),
    listenconfirmPassword(),
    listengetUsers(),
    listenAddUser(),
    listenUpdateUser(),
    listengetUserProfile(),
    listenupdateUserProfile(),
    listenResetPassword(),
    listenSetPassword(),
    listengetUserById(),
    listenSetSessionToken(),
    listenGetDealType(),

    //
    listenGetCountries(),
    listenGetStates(),
    listenGetPmsProducts(),
    listenGetReports(),
    listenGetExportedFile(),
    listenDeactivateUser(),
    listenChangePassword(),
    listenGetHotelFromHotelGroup(),
    listengetRoles(),
    listenGetHotel(),
    listenHotelManagementResetState(),
    listenAddTrackingData(),
    listenEmailService(),
    listenGetEmailService(),
    listenActivateUser(),
    listengetBookingStatus(),
    fetchHotelConfiguration(),

    fetchSoftDeleteCoupon(),
    listenCouponById(),
    listenGetCouponType(),
    listenaddCoupon(),
    listeneditCoupon(),
    listeneditSearchCoupon(),
    listengetCoupons(),
  ]);
}
