import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form as bootstrapForm, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Switch from "react-switch";
import * as yup from "yup";
import { PRIVATE_ROUTES, REGEX } from "../../helper/constants";
import actions from "../../store/actions/actions";
import { StorageService } from "./../../services/storage.service";
import { Utils } from "./../../utils/utils";
import { CKEditorComponent } from "./../Common/CkEditor";
import { DragDrop } from "./../Common/DragDrop";
import Spinner from "./../Common/Spinner";
import SingleDropDown from "../Common/singleDropDown";

export const SpecialOffersForm = (props) => {
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const history = useHistory();
  const [fileUploaded, setFileUploaded] = useState(null);
  const [content, setContent] = useState(null);
  const [editorState, setEditorState] = useState();
  const [imageFromApi, setimageFromApi] = useState([]);
  const [tid, setTid] = useState(null);
  const [language, setLanguage] = useState(null);
  const [allowAccess, setAllowAccess] = useState(true);
  const hotelDetails = useSelector((state) => state?.hotelDetails?.result);
  const gotSuccess = useSelector((state) => state?.spacialOffres?.success);
  const isAdd = useSelector((state) => state?.spacialOffres?.isAdd);
  const isLoading = useSelector((state) => state?.network?.isLoading);
  const pmsProducts = useSelector((state) => state?.constants?.pmsProducts);

  const currencySymbol = hotelDetails?.currency?.symbol;

  const [formInitialValue, setFormInitialValue] = useState({
    is_active: true,
    name: "",
    // description: "",
    price: "",
    start_date: "",
    end_date: "",
    has_no_end_date: false,
    pms_product_id: false,
  });
  const formSchema = yup.object().shape({
    is_active: false,
    name: yup.string().trim().min(1).required("Please Enter Special offers"),
    // description: yup.string().required("Please Enter Description"),
    price: yup.number().min(0).required("Please Enter Valid Service Price"),
    start_date: yup.date().required("Please Enter Start date"),
    end_date: yup.date().required().min(yup.ref("start_date")),
  });
  const setEndDateNull = (state, setFieldValue, values) => {
    setFieldValue("end_date", values.start_date);
  };

  const updateEditorState = (editorState, setFieldValue) => {
    setContent(editorState);

    if (Utils.getHTMLtoPlainText(editorState).trim().length) {
      setEditorState(editorState);
    } else {
      setEditorState("");
    }
  };
  const AddOffers = (payload) => {
    dispatch(actions.addOffer(payload));
  };
  const updateOffer = (payload) => {
    dispatch(actions.updateOffer(id, payload));
  };
  const onClickCancelButton = () => {
    history.push("/deals-and-offer");
  };

  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };

  useEffect(() => {
    dispatch(actions.getPmsProducts(hotelId));
  }, []);

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.offer_create));

    setLanguage(props.selectedLanguageForUpdate);
    if (props.offersDetails && props.isForEdit) {
      const des = Utils.langValueGenerator(
        props.offersDetails?.h_offers_and_services_ts || [],
        "body",
        props.selectedLanguageForUpdate
      );
      formikRef.current.setValues({
        is_active: props.offersDetails.is_active,
        name: Utils.langValueGenerator(
          props.offersDetails?.h_offers_and_services_ts || [],
          "name",
          props.selectedLanguageForUpdate
        ),
        price: props.offersDetails?.price,
        start_date: props.offersDetails?.start_date,
        end_date: props.offersDetails?.has_no_end_date
          ? props.offersDetails?.start_date
          : props.offersDetails?.end_date,
        has_no_end_date: props.offersDetails?.has_no_end_date,
        pms_product_id:
          props.offersDetails?.h_offers_and_services_pm?.pms_product_id,
      });
      const img = [];
      props.offersDetails?.h_offers_media?.map((image, i) =>
        img.push({
          arrId: image.id,
          preview: image.image_path,
          offerId: props.offersDetails.id,
        })
      );
      setEditorState(des || "");
      setContent(des || "");
      setimageFromApi(img);
      setTid(
        props.offersDetails?.h_offers_and_services_ts[
          props.selectedLanguageForUpdate - 1
        ]?.id
      );
    }
  }, [props.offersDetails, props.selectedLanguageForUpdate]);
  useEffect(() => {
    if (props.cmsContent) {
      setContent(props.cmsContent);
    }
  }, [props.cmsContent]);
  const clearForm = () => {
    formikRef.current?.resetForm();
  };

  useEffect(() => {
    if (gotSuccess && !props.isForEdit) {
      const htmlConverted = convertFromHTML(`<p></p>`);
      const finalizeContent = EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlConverted)
      );
      clearForm();
      setEditorState(finalizeContent);
    }
    if (isAdd) {
      history.push("/deals-and-offer");
    }
  }, [gotSuccess, isAdd]);

  //need to test

  const updateUplodedImage = () => {
    if (imageFromApi.length > 0) {
      if (imageFromApi[0].offerId == parseInt(id)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleOnPriceChange = (e, setFieldValue) => {
    const re = REGEX.NEGATIVE_REGEX;
    const value = parseFloat(
      e.target.value?.replace(currencySymbol, "").replaceAll(",", "")
    );

    if (value === "" || value > 0 || re.test(value)) {
      setFieldValue("price", value === "" ? "" : +(value * 1).toString());
    } else {
      setFieldValue("price", "");
    }
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          ...formInitialValue,
        }}
        validationSchema={formSchema}
        innerRef={formikRef}
        onSubmit={(values) => {
          if (!props.isForEdit) {
            const payload = {
              data: {
                ...values,
                description: editorState,
                hotel_id: hotelId,
                price: values.price && values.price > 0 ? values.price : 0,
              },
              image: fileUploaded,
            };
            AddOffers(payload);
          } else {
            const payload = {
              data: {
                ...values,
                description: editorState,
                language_id: language,
                tid: tid,
                image_deleted: fileUploaded?.length > 0 ? false : true,
              },
              image: fileUploaded,
            };
            updateOffer(payload);
          }
        }}
      >
        {({
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
          submitForm,
        }) => (
          <>
            {!isLoading ? (
              <Form>
                <Row
                  className="mt-3 sub-header-text"
                  style={{ fontSize: "13px" }}
                >
                  <label style={{ fontSize: "13px" }}>
                    {t(
                      "Modules.Deals and Offers (+ add new offer).Activate offer?"
                    )}
                  </label>
                  <div>
                    <span className="switch-lable">
                      {t("Modules.Deals and Offers (+ add new offer).Off")}
                    </span>
                    <Switch
                      onChange={(value) => {
                        setFieldValue("is_active", value);
                      }}
                      checked={values?.is_active}
                      name="is_active"
                    />
                    <span className="switch-lable">
                      {t("Modules.Deals and Offers (+ add new offer).On")}
                    </span>
                  </div>
                </Row>
                {hotelDetails?.auto_billing ? (
                  <Row>
                    <Col md={6}>
                      <bootstrapForm.Group>
                        <bootstrapForm.Label className="mt-2 sub-header-text">
                          PMS Product
                          <span style={{ color: "red" }}>*</span>
                        </bootstrapForm.Label>
                        <SingleDropDown
                          val={pmsProducts || []}
                          inpName={"pms_product_id"}
                          updateDropdown={setFieldValue}
                          touched={touched}
                          errors={errors}
                          values={values}
                        ></SingleDropDown>
                      </bootstrapForm.Group>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Row className="mt-3">
                  <Col md={6}>
                    <bootstrapForm.Group>
                      <bootstrapForm.Label className={"sub-header-text"}>
                        {t(
                          "Modules.Deals and Offers (+ add new offer).Offer name"
                        )}
                        <span style={{ color: "red" }}>*</span>
                      </bootstrapForm.Label>
                      <bootstrapForm.Control
                        type="text"
                        isInvalid={touched?.name && errors?.name}
                        onChange={handleChange}
                        value={values?.name.trimStart()}
                        name="name"
                      ></bootstrapForm.Control>
                    </bootstrapForm.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className={"sub-header-text"}>
                    <div className="description-header mb-2 ">
                      {t(
                        "Modules.Deals and Offers (+ add new offer).Offer description"
                      )}
                    </div>
                    {/* <TextEditor
                  name="description"
                  editorState={editorState}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setEditorState={(updateContent) => {
                    updateEditorState(updateContent, setFieldValue);
                  }}
                /> */}
                    <CKEditorComponent
                      name="description"
                      errors={errors}
                      editorState={editorState}
                      setFieldValue={setFieldValue}
                      newContent={content}
                      setEditorState={(updateContent) => {
                        updateEditorState(updateContent, setFieldValue);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className={"sub-header-text"}>
                    <div className="description-header mt-2 mb-2">
                      {t(
                        "Modules.Deals and Offers (+ add new offer).Add a picture to your offer"
                      )}
                    </div>
                    <DragDrop
                      maximumFile={1}
                      multiUpload={false}
                      handleChange={setFileUploaded}
                      values={imageFromApi}
                      // path={
                      //   props.isForEdit && imageFromApi.length > 0
                      //     ? "update"
                      //     : "add"
                      // }
                      path={updateUplodedImage() ? "update" : "add"}
                    ></DragDrop>
                  </Col>
                  <div>
                    <div style={{ fontSize: "small" }}>
                      {t("Common Translations.Picture Validation")}
                    </div>
                  </div>
                </Row>
                <Row>
                  <Col md={6}>
                    <bootstrapForm.Group>
                      <bootstrapForm.Label className="mt-2 sub-header-text">
                        {t(
                          "Modules.Deals and Offers (+ add new offer).Offer price"
                        )}{" "}
                        {currencySymbol && <>({currencySymbol})</>}
                        <span style={{ color: "red" }}>*</span>
                      </bootstrapForm.Label>
                      {Utils.NumberFormat(
                        currencySymbol,
                        `extra-service-price`,
                        values?.price,
                        "",
                        false,
                        touched?.price && errors?.price,
                        "price",
                        (e) => handleOnPriceChange(e, setFieldValue),
                        bootstrapForm.Control
                      )}
                    </bootstrapForm.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <bootstrapForm.Group>
                      <bootstrapForm.Label className={"sub-header-text"}>
                        {t(
                          "Modules.Deals and Offers (+ add new offer).Offer start date"
                        )}
                        <span style={{ color: "red" }}>*</span>
                      </bootstrapForm.Label>
                      <bootstrapForm.Control
                        type="date"
                        name="start_date"
                        isInvalid={touched?.start_date && errors?.start_date}
                        onChange={handleChange}
                        value={values?.start_date}
                      ></bootstrapForm.Control>
                    </bootstrapForm.Group>
                  </Col>
                  <Col md={3}>
                    <bootstrapForm.Group>
                      <bootstrapForm.Label className={"sub-header-text"}>
                        {t(
                          "Modules.Deals and Offers (+ add new offer).Offer end date"
                        )}
                        <span style={{ color: "red" }}>*</span>
                      </bootstrapForm.Label>
                      <bootstrapForm.Control
                        type="date"
                        name="end_date"
                        onChange={handleChange}
                        isInvalid={touched?.end_date && errors?.end_date}
                        value={values?.end_date}
                        min={values?.start_date}
                      ></bootstrapForm.Control>
                    </bootstrapForm.Group>
                  </Col>
                </Row>
                <hr className="border-line" />
                <Row className="mt-3">
                  {!props.isForEdit ? (
                    <Col md={2} sm={6} xs={6}>
                      {allowAccess ? (
                        <Button
                          variant="primary"
                          disabled={
                            props.selectedLanguageForUpdate !== props.baseLangId
                          }
                          onClick={() => {
                            submitForm();
                          }}
                        >
                          {t(
                            "Modules.Deals and Offers (+ add new offer).+ Add new offer"
                          )}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Col>
                  ) : (
                    <Col md={2} sm={6} xs={6}>
                      {allowAccess ? (
                        <Button
                          variant="primary"
                          disabled={
                            props.selectedLanguageForUpdate !== props.baseLangId
                          }
                          onClick={() => {
                            submitForm();
                          }}
                        >
                          {t(
                            "Modules.Deals and Offers (edit offer).Save button"
                          )}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Col>
                  )}
                  <Col md={2} sm={6} xs={6}>
                    <Button variant="secondary" onClick={onClickCancelButton}>
                      {t(
                        "Modules.Deals and Offers (+ add new offer).Cancel button"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
            ) : (
              <Spinner />
            )}
          </>
        )}
      </Formik>
    </React.Fragment>
  );
};
